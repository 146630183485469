.app2-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-right: none; /* 隐藏右边框 */
    border-bottom: none; /* 隐藏下边框 */
    border-left: none; /* 隐藏左边框 */
}

.app2-image {
    height: 20px;
    margin: 10px;
  }