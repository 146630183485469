.topic-overview-box{
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
}

.topic-overview-card{
    width: 16.66%;
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
}

.topic-overview-card-img{
    width: 100%;
    height: 85%;
}

.topic-overview-card-title{
    width: 100%;
    height: 15%;
    background-color: #ECECEC;
    font-size: 16px;
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.topic-overview-card-img-png{
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}

.topic-overview-card-img-png:hover{
    filter: grayscale(0%);
}