
.radar-container-container{
  height: 100%;
}
.radar-container {
    position: relative;
    width: 46%; 
    height: 253px; 
    border: 1px solid rgb(224, 224, 224);
    border-radius: 10px; 
    box-shadow: 0px 0px 5px #E0E0E0; 
    margin-left: 2%;
    margin-right: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
    float: left;
  }
  
  .radar-container .chart {
    position: absolute;
    width: 50%;
    height: 67.2%;
    top: 0;
    right: 0; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* 样式头像图片 */
  .radar-container .avatar {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 60px; 
    height: 60px;
    border-radius: 50%; 
  }
  .radar-container .chinese_name {
    position: absolute;
    top: 38px; /* 调整文本的垂直位置 */
    left: 100px;
    font-size: 15px;
    color: rgb(71,71,71);
    text-align: center; /* 文本居中对齐 */
  }
  .radar-container .name {
    position: absolute;
    width: 150px;
    top: 60px; /* 调整文本的垂直位置 */
    left: 86px;
    font-size: 12px;
    transform: scale(0.8);
    display: inline-block;
    color: rgb(71,71,71);
  }
  .radar-container .type-wrapper {
    margin-top: 5px;
    position: absolute;
    top: 70px;
    left:98px;
  }

  .radar-container .type,
  .radar-container .position {
    color: rgb(27, 27, 27);
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    white-space: nowrap; /* 防止文本换行 */
    display: inline-block; /* 将元素变为行内块元素以自适应宽度 */
    max-width: 100%; /* 限制最大宽度 */
    font-size: 12px; /* 使用自适应字体大小 */
  }
  .radar-container .type{
    margin-right: 5px;
  }
  .radar-container .party_name {
    position: absolute;
    top: 160px; 
    left: 21.2%;
    width: 90px;
    font-size: 22px;
    color: rgb(19,124,183);
    text-align: center; 
  }
  .radar-container .party {
    position: absolute;
    top: 195px; 
    left: 28.6%;
    font-size: 14px;
    color: rgb(138,94,92);
    text-align: center; 
  }
  .radar-container .influence_value {
    position: absolute;
    top: 160px; 
    right: 27.1%;
    font-size: 22px;
    color: rgb(19,124,183);
    text-align: center;
  }
  .radar-container .influence {
    position: absolute;
    top: 195px;
    right:23.6%;
    font-size: 14px;
    color: rgb(138,94,92);
    text-align: center; 
  }

  .radar-container .datePicker {
    position: absolute;
    bottom: 5px; 
    right: 50%;
    transform: translate( 50%,0); 
    text-align: center;
  }
  .radar-container .divider-line {
    position: absolute;
    height:1px; 
    width:90%;
    margin-right: 5%;
    margin-left: 5%;
    background-color: #CBCBCB; 
    margin-top: 10px; 
    margin-bottom: 10px; 
    top: 207px;
  }
  .radar-container .dateButtons {
    display: flex;
    justify-content: center;
  }

  .radar-container .dateButtons button {
    padding: 5px 10px;
    background-color: transparent; 
    border: none;
    color: #9a9a9a; 
    cursor: pointer;
    margin: 0 5px;
  }
  .radar-container .date_value {
    color: #6a6a6a;
    padding-top: 2px;
    font-size: 14px;
  }
