#elite-overview {
    display: flex;
    justify-content: space-between;
    width: 100%; 
    height: 100%;
    padding: 20px 0;
    box-sizing: border-box; 
}

.aside1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30%;
    width: 100%;
    margin:0px 15px 15px 15px;
    background-color: #f4f4f4;
    border-radius: 10px;
    /* 设置背景颜色 */
}
.aside {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30%;
    width: 100%;
    margin:0px 15px 0px 15px;
    background-color: #f4f4f4;
    border-radius: 10px;
    /* 设置背景颜色 */
}
.aside2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 14%;
    margin:0 15px 0 15px;
    background-color: #f4f4f4;
    border-radius: 10px;
    height: 100%;
}
.asides {
    width:14%;
    display: flex;
    margin:0 15px 0 15px;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.middle {
    flex: 2;
    width:69%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100%;
}
.middle-row1 {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7.5px;
}
.middle-row2 {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7.5px;
}

.aside-up{
    text-align: center;
    background-color: #f4f4f4;
    width: 95%;
    font-size: 35px;
    height: 60%;
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.aside-low{
    font-size: 24px;
    border-top: 2px solid #D7D7D7;
    width: 95%;
    height: 40%;
    text-align: center;
    margin-bottom: 5%;
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.middle-up {
    text-align: center;
    width: 100%;
    background-color: #f4f4f4;
    flex-grow: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right:0px;
    border-radius: 10px;
    height: 100%;
}

.middle-card {
    display: inline-block;
    width: 23%;
    height: 100%;
    text-align: center;
    margin: 10px 7.5px 10px 7.5px;
    background-color: white;
    border-radius: 10px;
}
.middle-card-up{
    height: 60%;
    font-size: 30px;
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.middle-card-low3{
    border-top: 2px solid #B4B7C5;
    font-size: 20px;
    height: 40%;
    display: flex; 
    align-items: center; 
    justify-content: center;
}
.middle-card-low{
    border-top: 2px solid #D7D7D7;
    font-size: 20px;
    height: 40%;
    display: flex; 
    align-items: center; 
    justify-content: center;
}
.middle-card-up2{
    height: 60%;
    font-size: 30px;
    color: white;
    display: flex; 
    align-items: center; 
    justify-content: center;
}
.middle-card-low2{
    border-top: 2px solid #D7D7D7;
    font-size: 20px;
    height: 40%;
    display: flex; 
    color: white;
    align-items: center; 
    justify-content: center;
}
.ant-card .ant-card-body {
    height: calc(100% - 40px);
}