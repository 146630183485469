.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: black;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: black;
  /* color: white; */
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: black;
  /* color: white; */
}

.ant-popover-inner-content {
  max-width: 300px;
}

.network-card {
  position: absolute;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  pointer-events: none;
  line-height: 1.5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 200px;
}  