#article-influence-chart-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

/* 图表header */
.header {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    border-radius: 5px 5px 0 0;
    background-color: #d9d9d9;
    padding: 10px 20px;
    width: 100%;
}

/* 整个图表的大标题 */
.header-title {
    font-size: 20px;
    font-weight: bolder;
}

/* 绘制legend区域 */
.legend-container {
    text-align: right;
    width: 100%;
}

.legends {
    float: right !important;
}

/* 绘制图标区域 */
.chart-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 650px;
    padding: 0px 10px 10px 10px;
    overflow-y: scroll;
}

/* 每个图表的外边框 */
.box {
    width: 19%;
    height: 330px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    margin: 0.5%;
}

/* 每个智库的标题div */
.title-box {
    width: 100%;
    height: 50px;
    text-align: center;
    border-radius: 5px 5px 0 0;
    background-color: #d9d9d9;
}

/* 绘制图表的div */
.chart-box {
    width: 100%;
    height: 280px;
    text-align: center;
    margin: 0px;
    /* border: 1px solid #c82a2a; */

}

/* 智库logo */
.logo-img {
    height: 50px;
    max-width: 100%;
}

/* 好看的滚动条 */
/* ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 5px solid transparent;
}

::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
}

::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2) inset;
}

::-webkit-scrollbar-corner {
    background: transparent;
} */