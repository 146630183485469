.case-page{
    background-color: white;
}

.link-box{
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.link-box-btn{
    width: 95%;
    height: 7vh;
    background-color: #E9ECF6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-size: 20px;
    color: #4C4C4C;
}