
#avatar_container {
    /* width: 500px; */
    height: 1100px;

}

#author-overview-app {
    overflow: scroll;
}

#mediaSelector {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-right: none; /* 隐藏右边框 */
    border-bottom: none; /* 隐藏下边框 */
    border-left: none; /* 隐藏左边框 */
    background-color: white;
}

#prevMedia,
#nextMedia {
    background-color: transparent;
    border: none;
    color: #808080;
    /* 灰色 */
    font-size: 24px;
    cursor: pointer;
}

#currentMedia {
    font-size: 17px;
    margin: 0 20px;
}

.author-overview-media-item {
    width: 96%;
    height: auto;
    border: 1px solid #ccc;
    margin: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px #E0E0E0;
    /* justify-content: space-between; */
    border-radius: 5px;
    float: left;
}

#avatar_container img {
    padding-left: 5px;
    height: 75px;
    width: 75px;
}

#avatar_container .media-name {
    padding-left: 3px;
    margin-right: 5px;
}

#avatar_container .chinese {
    margin: 0px;
    font-size: 16px;
}

#avatar_container .english {
    margin-top: 1px;
    font-size: 12px;
    margin-bottom: 2px;
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
}

#avatar_container .media-logo {
    margin-left: 5%;
    margin-right: 3%;
}

#avatar_container .type-wrapper {
    margin-top: 3px;
    height: 5%;
    border-radius: 3px;
    margin-bottom: 3px;
    margin-right: 10px;
}

#avatar_container .type,
#avatar_container .position {
    color: rgb(27, 27, 27);
    height: 100%;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: inline-block;
    /* 将元素变为行内块元素以自适应宽度 */
    max-width: 100%;
    /* 限制最大宽度 */
    font-size: 12px;
    /* 使用自适应字体大小 */
}

#avatar_container .type {
    margin-right: 5px;
    margin-bottom: 2px;
}

#avatar_container .unit {
    font-size: 13px;
    margin: 0px;
    color: #945B5B;
}

#avatar_container .stat_item {
    vertical-align: top;
}

#avatar_container .value {
    color: #000000;
    margin: 5%;
    font-size: 14px;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    overflow: auto;
    max-height: 150px;
}

#avatar_container .line_one {
    display: flex;
    align-items: center;
    height: 20%;
    margin-top: 1%;
    color: #474747;
}

#avatar_container .line_two {
    display: flex;
    margin-top: 1%;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    height: 58%;
    margin-bottom: 1%;
    vertical-align: top;
}

#avatar_container .line_three {
    text-align: center;
    display: flex;
    /* 使用 Flex 布局 */
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    height: 10%;
    margin-bottom: 10px;
    border-bottom: 1px solid #CBCBCB;
    margin: 0px 10px 0px 10px;
    padding: 3px 0 3px 0;
    color: #6A6A6A;
    font-size: 16px;
}

#avatar_container .number {
    margin-top: 6px;
    font-size: medium;
    margin-bottom: 6px;
    color: #6A6A6A;
    font-weight: bold;

}