#congress-overview-container{
    padding-left: 1vw;
    height: 80vh;
    overflow-y: auto;
}
.congress-item {
    width: 31%;
    height: 254px;
    border: 1px solid #ccc;
    margin: 1%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px #E0E0E0; 
    /* justify-content: space-between; */
    border-radius: 5px;
    float: left;
}
.first-value {
    padding: 0px 5px 0px 5px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    color: white; /* 将 value 文本字体颜色设置为白色 */
  }
.first-unit {
    font-size: 15px;
    margin-left: 0px;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 0px;
    color: #222222; /* 将 unit 文本字体颜色设置为黑色 */
  }
.first-item2 {color:white;}
.first-item {
    width: 31%;
    height: 254px;
    border: 1px solid #ccc;
    margin: 1%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px #E0E0E0; 
    /* justify-content: space-between; */
    border-radius: 5px;
    float: left;
    background-color: #B4B7C5; /* 或者其他灰色的颜色值 */
    /* 其他样式属性 */
  }
  
.media-name{
    padding-left: 3px;
    margin-right: 5px;
}
.chinese{
    margin: 0px;
    font-size: 18px;
}
.english{
    margin-top: 1px;
    font-size: 14px;
    margin-bottom:4px;
    font-family: Arial, Helvetica, sans-serif;
    width: 130px;
    height: auto;
}
.congress-item .first-item-media-time{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    width: 33%;
    height: 71px;
    background-color:white;
    border-radius: 3px;
    margin-left: 30px;
    margin-bottom: 8px;
    margin-right: 10px;
}
.congress-item .media-time{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    width: 33%;
    height: 71px;
    background-color: #f4f4f4;
    border-radius: 3px;
    margin-left: 30px;
    margin-bottom: 8px;
    margin-right: 10px;
}

.congress-item .media-time-up{
    padding: 5px 0px 5px 0px;
    font-size: 20px;
    color: black;
}

.congress-item .media-time-low{
    padding: 3px 0px 3px 0px;
    color: black;
    font-size: 13px;
    border-top: 1px solid #D7D7D7;
}
.congress-item .media-time-range{
    padding: 7px 0px 7px 0px;
    width:70%;
    height:19px;
    font-size: 20px;
    color: black;
}
.congress-item .time_text{            
    color: black;
    width:30%;
    padding: 12px 10px 12px 20px;
    height:19px;
    font-size: 13px;
}
.congress-item .first-item-media-rate {
    width: 33%;
    height: 71px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 3px;
    text-align: center;
    background-color: white; 
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item .media-rate {
    width: 33%;
    height: 71px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 3px;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item .first-congress_21{
    width: 21.5%;
    height: 71px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 30px;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item .first-congress_22{
    width: 21.5%;
    height: 71px;
    margin-left: 11px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item .first-congress_23{
    width: 69%;
    height: 43px;
    margin-left: 30px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item .congress_21{
    width: 21.5%;
    height: 71px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 30px;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item .congress_22{
    width: 21.3%;
    height: 71px;
    margin-left: 11px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item .congress_23{
    width: 69%;
    height: 43px;
    margin-left: 30px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 8px;
}
.congress-item.first-data {    width: 65px;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 4px;
    margin-left: 20px;
    margin-bottom: 20px;}
.congress-item .stat-item{
    width: 80px;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 4px;
    margin-left: 15px;
    margin-bottom: 20px;
}
.congress-item .unit{
    font-size: 15px;
    margin-left: 0px;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 0px;
    color:#945B5B;
}

.congress-item .value{
    color: #0685E9;
    padding: 0px 5px 0px 5px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
}

.congress-item .line_one{
    display: flex;
    align-items: center;
    height: 70px;
    margin-top: 25px;
    color: #474747;
}
.congress-item .line_two{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 14px;
    height: 70px;
}
.congress-item .line_three {
    display: flex;
    align-items: center;
    margin-top: 14px;
    height: 45px;
    color: #474747;
}

.number{
    margin-top: 10px;
    font-size: medium;
    margin-top: 6px;
    color: #6A6A6A;
}
.congress_23_container{
    display: flex;
    text-align: center;
    width: 100%;
    height: 43px;
}