.tooltip {
    position: absolute;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    pointer-events: none;
    line-height: 1.5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: block;
}

.rowTitle {
    font-size: 14px;
    max-width: 30px;
    text-wrap: break-word;
}