#media-overview-container{
    padding-left: 1vw;
}
.media-item {
    width: 31%;
    height: 254px;
    border: 1px solid #ccc;
    margin: 1%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px #E0E0E0; 
    /* justify-content: space-between; */
    border-radius: 5px;
    float: left;
}

.media-logo img {
    /* padding-left: 5px; */
    height: 75px;
    width: 75px;
}

.media-name{
    padding-left: 3px;
    margin-right: 5px;
}
.chinese{
    margin: 0px;
    font-size: 18px;
}
.english{
    margin-top: 1px;
    font-size: 14px;
    margin-bottom:4px;
    font-family: Arial, Helvetica, sans-serif;
    width: 130px;
    height: auto;
}

.media-item .media-time{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 3px;
    margin-bottom: 8px;
    margin-right: 10px;
}

.media-item .media-time-up{
    padding: 10px 18px 10px 18px;
    font-size: 20px;
    color: black;
}

.media-item .media-time-low{
    color: black;
    padding: 4px 18px 4px 18px;
    font-size: 13px;
    border-top: 1px solid #D7D7D7;
}

.media-item .media-rate {
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 3px;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 8px;
}
.media-item .unit{
    font-size: 13px;
    margin: 0px;
    color:#945B5B;
}
.media-item .value{
    color: #0685E9;
    font-size: 22px;
    margin:5px;
    font-family: Arial, Helvetica, sans-serif;
}

.media-item .line_one{
    display: flex;
    align-items: center;
    /* height: 221px; */
    margin-top: 14px;
    color: #474747;
}
.media-item .line_two{
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    text-align: center;
    height: 70px;
    margin-bottom: 5px;
}
.media-item .line_three {
    text-align: center;
    height: 36px;
    border-top: 1px solid #CBCBCB;
    margin:0px 10px 0px 10px;
    padding: 3px 0 3px 0;
    color: #6A6A6A;
    font-size: 16px;
}

.media-item .number{
    margin-top: 10px;
    font-size: medium;
    margin-top: 6px;
    color: #6A6A6A;
}