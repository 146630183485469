
/* 页面整个区域 */
.media-barchart-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    padding-left: 1vw;
}

/* 标题区域 */
.media-barchart-container .header {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    border-radius: 5px 5px 0 0;
    background-color: #d9d9d9;
    padding: 10px 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

/* 文字大标题 */
.media-barchart-container .header-title {
    font-family: "Source Han Sans";
    font-size: 20px;
    font-weight: bolder;
}

.media-barchart-container img {
    width: 200px;
    height: 25px;
    margin: 12.5px 0;
}

/* 绘制图表的整体区域 */
.media-barchart-container .charts-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 1400px;
    align-self: center;
    margin: 10px;
}

/* 每个媒体的图表绘制区域 */
.media-barchart-container .one-medium-charts-div {
    width: 19%;
    margin: 0.4%;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

/* 每个媒体的logo区域 */
.media-barchart-container .medium-logo-div {
    background-color: #d9d9d9;
    width: 100%;
    height: 50px;
    border-radius: 5px 5px 0 0;
}

.media-barchart-container .chart-div {
    /* width: 240px; */
    padding: 10px;
    /* height: 550px; */
    overflow-y: scroll;
}