.container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 0.5vw;
}

/* 绘制图标区域 */
.btn-container {
    width: 100%;
    height: 65px;
    /* text-align: center; */
    padding: 10px;
}

/* 样式化按钮组容器 */
.button-group {
    display: inline-block;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin: 10px 0px;
    width: 100%;
}

/* 样式化按钮 */
.button-group button {
    width: 33.333333%;
    height: 43px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    /* padding: 5px 3.3vw; */
    display: inline-block;
    /* 将按钮变成块级元素 */
    vertical-align: middle;
    /* 垂直居中对齐 */
}

/* 添加选中按钮的样式 */
.button-group button.active {
    background-color: #d9d9d9;
    /* padding: 5px 3.3vw; */
}

/* 绘制表格区域 */
.table-container {
    height: 53vh;
    width: 100%;
    overflow-y: auto; /* Add overflow-y for vertical scrolling */
    padding: 10px; 
}

/* Center rankCell for the first button */
.center-first-button {
    padding: 0px 4vw;
    text-align: center;
}

.center-second-and-third-buttons {
    text-align: left;
    padding-left: 4.5vw; /* Adjust the padding to match your desired alignment */
}


table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px 0 solid #ddd;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    height: 10px;
}

tr:nth-child(odd) {
    background-color: #f2f2f2;
    /* 偶数行斑马纹背景色 */
}


/* @font-face {
    font-family: 'Source Han Sans';
    src: url('./fonts/SourceHanSansSC-VF.otf.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
} */