#think-overview{
    padding-left: 1vw;
}
.think-item {
    width: 31%;
    height: 254px;
    border: 1px solid #ccc;
    margin: 1%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px #E0E0E0;
    border-radius: 5px;
    float: left;
}

.think-item .think-overview-img {
    margin-left:45px ;
    margin-bottom: 15px;
    height: 65px;
    width: 65px;
}

.think-item .media-name {
    width: 70%;
    padding-left: 15px;
    margin-right: 5px;
    padding-bottom: 20px;
}

.think-item .chinese {
    margin: 0px;
    font-size: 20px;
}

.think-item .english {
    margin-top: 1px;
    font-size: 16px;
    margin-bottom: 4px;
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
    width: 100%;
}

.think-item .media-time {
    width: 40%;
    height: 85px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 3px;
    margin-bottom: 50px;
    margin-right: 10px;
}

.think-item .media-time-up {
    padding: 10px 18px 10px 18px;
    font-size: 24px;
    color: black;
}

.think-item .media-time-low {
    color: black;
    top: 30px;
    font-size: 16px;
    border-top: 1px solid #D7D7D7;
}

.think-item .media-rate {
    width: 40%;
    height: 85px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 3px;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 50px;
}

.think-item .unit {
    font-size: 13px;
    margin: 0px;
    color: #945B5B;
}

.think-item .value {
    color: #0685E9;
    font-size: 22px;
    margin: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.think-item .line_one {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 40px;
    height:90px;
    color: #474747;
}

.think-item .line_two {
    margin-top: 10px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 5px;
}

.think-item .number {
    margin-top: 10px;
    font-size: medium;
    margin-top: 6px;
    color: #6A6A6A;
}
